import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, tap, catchError } from 'rxjs/operators';
import { empty, throwError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ITenantsApi, Tenant, User, IUsersResult, Subscription, UpgradeInquiry } from 'app/tenants/tenant.model';
// import Axios from  'axios-observable';
// import Axios from '@app/blocks/utils/axios-observable';
// import Axios from '../utils/axios-observable';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { AuthService } from '../security/auth-service';
import { handleError } from '../utils/exception.helper';


@Injectable()
export class SubscriptionsService {

    private baseUrl: string;
    private tenantsBaseUrl: string;
    /**
    * Constructor
    * @param {HttpClient} _httpClient
    */
    constructor(
        private httpClient: HttpClient,
        private _authService: AuthService
    ) {
        this.baseUrl = environment.backEnd;
        this.tenantsBaseUrl = this.baseUrl + '/api/subscriptionAdmin';
    }

    getSubscriptionById(id: string): Observable<Subscription> {
        return this.httpClient
            .get<Subscription>(`${this.tenantsBaseUrl}/getSubscriptionById/${id}`);
    }

    getCurrentUpgradeSubscription(tenantId: string): Observable<Subscription> {
        return this.httpClient
            .get<Subscription>(`${this.tenantsBaseUrl}/${tenantId}/getCurrentUpgradeSubscription`);
    }

    getUpgradeSubscriptionInquiry(tenantId: string,subscription: Subscription): Observable<UpgradeInquiry> {
        return ajax.get(
            `${this.tenantsBaseUrl}/${tenantId}/getUpgradeSubscriptionInquiry?durationUnit=${subscription.durationUnit}&duration=${subscription.duration}&planId=${subscription.planId}`,
            {
                Authorization: `Bearer ${this._authService.getAccessToken()}`,
                'Content-Type': 'application/json'
            },
            ).pipe(
                map(response => response.response),
                catchError(handleError)
            );
        // return this.httpClient
        //     .get<Subscription>(`${this.tenantsBaseUrl}/${tenantId}/getUpgradeSubscriptionInquiry?durationUnit=month&duration=3&planId=660570837c41d74f7c2af655`);
    }

    addSubscription(tenantId: string, data: any): Observable<Subscription> {
        return ajax.post(
            `${this.tenantsBaseUrl}/${tenantId}/addSubscription`,
            data            , 
            {
                Authorization: `Bearer ${this._authService.getAccessToken()}`,
                'Content-Type': 'application/json'
            },
            ).pipe(
                map(response => response.response),
                catchError(handleError)
            );

        // return this.httpClient
        //     .post<Subscription>(`${this.tenantsBaseUrl}/${tenantId}/addSubscription`, data);
    }
    
    cancelUpgradeSubscription(tenantId: string) {

        return ajax.put(
            `${this.tenantsBaseUrl}/${tenantId}/cancelUpgradeSubscription`,
            null, 
            {
                Authorization: `Bearer ${this._authService.getAccessToken()}`,
                'Content-Type': 'application/json'
            },
            ).pipe(
                map(response => response.response),
                catchError(handleError)
            );
    }
    updateSubscription(tenantId: string, data: any) {

        return ajax.put(
            `${this.tenantsBaseUrl}/${tenantId}/updateSubscription`,
            data, 
            {
                Authorization: `Bearer ${this._authService.getAccessToken()}`,
                'Content-Type': 'application/json'
            },
            ).pipe(
                map(response => response.response),
                catchError(handleError)
            );
    }

    updateSubscriptionStatus(tenantId: string, data: any) {

        return ajax.put(
            `${this.tenantsBaseUrl}/${tenantId}/updateSubscriptionStatus`,
            data, 
            {
                Authorization: `Bearer ${this._authService.getAccessToken()}`,
                'Content-Type': 'application/json'
            },
            ).pipe(
                map(response => response.response),
                catchError(handleError)
            );
    }

    addTrial(tenantId: string, data: any) {

        return ajax.put(
            `${this.tenantsBaseUrl}/${tenantId}/addTrialSubscription`,
            data, 
            {
                Authorization: `Bearer ${this._authService.getAccessToken()}`,
                'Content-Type': 'application/json'
            },
            ).pipe(
                map(response => response.response),
                catchError(handleError)
            );
    }
}


