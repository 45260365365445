import { IRole } from './../../roles/roles.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class RolesService {

    private baseUrl: string;


    /**
    * Constructor
    * @param {HttpClient} _httpClient
    */
    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.backEnd;
    }

    getRoles(): Observable<IRole[]> {
        return this.httpClient.get<IRole[]>(this.baseUrl + '/roles');
    }

    createRole(data): Observable<IRole> {
        return this.httpClient.post<IRole>(this.baseUrl + '/roles', data);
    }

    getRoleById(id): Observable<IRole> {
        return this.httpClient.get<IRole>(this.baseUrl + '/roles/' + id);
    }

    updateRole(id, data): Observable<void> {
        return this.httpClient.put<void>(this.baseUrl + '/roles/' + id, data);
    }
}
