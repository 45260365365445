export const environment = {
    production: true,
    hmr: false,

    // -----------------------------------------
    //      @ BACKEND
    // -----------------------------------------
    backEnd: 'https://staging-api.medicareweb.in',

    // -----------------------------------------
    //      @ APP SECURITY
    // -----------------------------------------
    issuer: 'https://staging-login.medicareweb.in',
    clientId: 'medcilia-admin',
    scope: 'openid profile administration',
    redirectUri: 'https://staging-ams.medicareweb.in/',
    silentRefreshRedirectUri: 'https://staging-ams.medicareweb.in/silent-refresh.html',
    postLogoutRedirectUri: 'https://staging-ams.medicareweb.in/',

    // -----------------------------------------
    //      @ GRAPGQL
    // -----------------------------------------
    GQL_SCHEMA_VERSION: '0.2'
};
