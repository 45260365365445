import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Medcilia Admin',
        // translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'Home',
                title    : 'Home',
                // translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'home',
                url      : '/home',
            },
            {
                id       : 'Tenants',
                title    : 'Tenants',
                // translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'important_devices',
                url      : '/tenants',
            },
            {
                id       : 'Roles',
                title    : 'Roles',
                // translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'card_travel',
                url      : '/roles',
            },
        ]
    }
];
