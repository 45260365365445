<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/fuse.svg">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            Medcilia Admin
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            The management portal for Medcilia
        </div>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">

            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/fuse.svg">
            </div>

            <div class="title">LOGIN TO YOUR ACCOUNT</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <!-- <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email">
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput type="password" placeholder="Password" formControlName="password">
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field> -->

                <!-- <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/pages/auth/forgot-password-2'">
                        Forgot Password?
                    </a>
                </div> -->

                <button 
                    mat-raised-button 
                    color="accent" 
                    class="submit-button" 
                    aria-label="LOGIN"
                    (click)="login($event)">
                    LOGIN
                </button>

            </form>

            <!--<div class="separator">
                <span class="text">OR</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start center">

                 <button mat-raised-button class="google">
                    Log in with Google
                </button>

                <button mat-raised-button class="facebook">
                    Log in with Facebook
                </button> 

            </div>-->

            <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register-2'">Get a free trial account</a>
            </div> -->
        </div>
    </div>
</div>