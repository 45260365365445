import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { 
    MatButtonModule, 
    MatCheckboxModule, 
    MatFormFieldModule, 
    MatInputModule 
} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from './login.component';
import { AuthPublicGuard } from 'app/blocks/security/auth-public-guard.service';

const routes = [
    {
        path: 'login',
        data: { breadcrumb: 'Login' },
        component: LoginComponent,
        // canActivate: [AuthPublicGuard]
    }
];

@NgModule({
    declarations: [
        LoginComponent
    ],    
    imports: [
        RouterModule.forChild(routes),
        
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,

        FuseSharedModule
    ],
    providers: []
})
export class LoginModule {
}
