/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i3 from "../../@fuse/services/config.service";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/router";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/common";
import * as i9 from "./not-found.component";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "page-layout blank"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.FusePerfectScrollbarDirective, [i1.ElementRef, i3.FuseConfigService, i4.Platform, i5.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center center"], ["id", "error-404"]], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i6.LayoutDirective, [i7.MediaMonitor, i1.ElementRef, i7.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(4, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "content"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(6, 737280, null, 0, i6.LayoutDirective, [i7.MediaMonitor, i1.ElementRef, i7.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(7, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Not found "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry but we could not find the page you are looking for"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "back-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Go back to Home"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = "column"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "column"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "center center"; _ck(_v, 7, 0, currVal_4); var currVal_7 = "/home"; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var currVal_5 = i1.ɵnov(_v, 13).target; var currVal_6 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_5, currVal_6); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i9.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found", i9.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
