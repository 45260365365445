import { LoginComponent } from './login.component';
var ɵ0 = { breadcrumb: 'Login' };
var routes = [
    {
        path: 'login',
        data: ɵ0,
        component: LoginComponent,
    }
];
var LoginModule = /** @class */ (function () {
    function LoginModule() {
    }
    return LoginModule;
}());
export { LoginModule };
export { ɵ0 };
