/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../@fuse/components/progress-bar/progress-bar.component.ngfactory";
import * as i3 from "../@fuse/components/progress-bar/progress-bar.component";
import * as i4 from "../@fuse/components/progress-bar/progress-bar.service";
import * as i5 from "./layout/vertical/layout-1/layout-1.component.ngfactory";
import * as i6 from "./layout/vertical/layout-1/layout-1.component";
import * as i7 from "../@fuse/services/config.service";
import * as i8 from "./app.component";
import * as i9 from "@angular/common";
import * as i10 from "../@fuse/components/navigation/navigation.service";
import * as i11 from "../@fuse/components/sidebar/sidebar.service";
import * as i12 from "../@fuse/services/splash-screen.service";
import * as i13 from "../@fuse/services/translation-loader.service";
import * as i14 from "@ngx-translate/core";
import * as i15 from "@angular/cdk/platform";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, i2.View_FuseProgressBarComponent_0, i2.RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i3.FuseProgressBarComponent, [i4.FuseProgressBarService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "vertical-layout-1", [], null, null, null, i5.View_VerticalLayout1Component_0, i5.RenderType_VerticalLayout1Component)), i1.ɵdid(4, 245760, null, 0, i6.VerticalLayout1Component, [i7.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppComponent, [i9.DOCUMENT, i7.FuseConfigService, i10.FuseNavigationService, i11.FuseSidebarService, i12.FuseSplashScreenService, i13.FuseTranslationLoaderService, i14.TranslateService, i15.Platform], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
