import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './blocks/security/auth-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';
var ɵ0 = { breadcrumb: 'Home' }, ɵ1 = { breadcrumb: 'Tenants' }, ɵ2 = { breadcrumb: 'Roles' };
var appRoutes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    // @ load secured feature modules 
    {
        path: '', component: AppComponent,
        children: [
            {
                path: 'home',
                loadChildren: './main/home/home.module#HomeModule',
                data: ɵ0,
                canActivate: [AuthGuard]
            },
            {
                path: 'tenants',
                loadChildren: './tenants/tenants.module#TenantsModule',
                data: ɵ1,
                canActivate: [AuthGuard]
            },
            {
                path: 'roles',
                loadChildren: './roles/roles.module#RolesModule',
                data: ɵ2,
                canActivate: [AuthGuard]
            },
            {
                path: 'not-found', component: NotFoundComponent
            }
        ]
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
