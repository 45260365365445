import * as tslib_1 from "tslib";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
var MomentUtcDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(MomentUtcDateAdapter, _super);
    function MomentUtcDateAdapter(dateLocale) {
        return _super.call(this, dateLocale) || this;
    }
    MomentUtcDateAdapter.prototype.createDate = function (year, month, date) {
        // Moment.js will create an invalid date if any of the components are out of bounds, but we
        // explicitly check each case so we can throw more descriptive errors.
        if (month < 0 || month > 11) {
            throw Error("Invalid month index \"" + month + "\". Month index has to be between 0 and 11.");
        }
        if (date < 1) {
            throw Error("Invalid date \"" + date + "\". Date has to be greater than 0.");
        }
        var result = moment.utc({ year: year, month: month, date: date }).locale(this.locale);
        // If the result isn't valid, the date must have been out of bounds for this month.
        if (!result.isValid()) {
            throw Error("Invalid date \"" + date + "\" for month with index \"" + month + "\".");
        }
        return result;
    };
    return MomentUtcDateAdapter;
}(MomentDateAdapter));
export { MomentUtcDateAdapter };
