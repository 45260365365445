import {ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatButtonModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { GlobalErrorHandlerService } from './blocks/common/GlobalErrorHandler.service';
import { ShellComponent } from './main/shell/shell.component';
// import { SampleComponent } from './main/sample/sample.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './main/login/login.module';
import { ServicesModule } from './blocks/services/services.module';
import { SecurityModule } from './blocks/security/security.module';
import { environment } from 'environments/environment';
import { NotFoundComponent } from './not-found/not-found.component';
import { MomentUtcDateAdapter } from './blocks/common/mmentUtcDateAdapter.service';

@NgModule({
    declarations: [
        AppComponent,
        ShellComponent,
        NotFoundComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        ServicesModule.forRoot(),
        
        // RouterModule.forRoot(appRoutes),
        AppRoutingModule,

        LoginModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        // SampleModule,
        SecurityModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    bootstrap: [
        // AppComponent,
        ShellComponent,
    ],
    providers: [
        // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ]
})
export class AppModule {
}
