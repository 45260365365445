import { Optional, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Subject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth-service';


export class AuthInterceptor implements HttpInterceptor {
    
    refreshTokenInProgress = false;
    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    authService;

    constructor(
        @Optional() private injector: Injector,
        private router: Router
    ){}

    addAuthHeader(request): any {
        const authHeader = this.authService.getAccessToken();
        if (authHeader){
            return request.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + authHeader
                }
            });
        }
        return request;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

        this.authService = this.injector.get(AuthService);
        request = this.addAuthHeader(request);

        // Handle response
        return next.handle(request)
            .pipe(
                catchError(error => {

                    // Check for 401 / Unauthorized
                    if (error.status === 401) {

                        let idTokenExpired = false;
                        let tokenExpired = false;
                        const authData = this.authService.isStatelessSession();
                        if (authData) {
                            idTokenExpired = new Date() > new Date(this.authService.getIdTokenExpiration());
                            tokenExpired = new Date() > new Date(this.authService.getAccessTokenExpiration());

                            // Check if idToken is not expired and token is expired
                            // then try to refresh token
                            if (!idTokenExpired && tokenExpired) {
                                this.authService.refreshToken()
                                    .then(_ => {
                                        next.handle(request);
                                    })
                                    .catch((_) => {
                                        // token has not refreshed
                                        this.authService.logout();
                                        return throwError(request);
                                    });
                            } else {
                                // token not expired and idToken not expired,
                                // so Unauthorized action ?
                                this.authService.logout();
                                return throwError(request);
                            }
                        } else {
                            // Session is stateless
                            this.router.navigate(['/login']);
                            return throwError(request);
                        }

                    } else if (error.status === 403) { // Forbidden

                        const hasValidToken = this.authService.hasValidToken();
                        if (hasValidToken) {
                            // We have a valid token, just redirect to lock screen
                            this.router.navigate(['/login']);
                        } else {
                            this.authService.logout();
                            return throwError(request);
                        }
                    } else {  // Not 401, 403, then return the request
                        return throwError(request);
                    }
                    return throwError(error);
                })
            );
    }

}
