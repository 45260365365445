import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { AuthGuard } from './blocks/security/auth-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';


const appRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },    
    // @ load secured feature modules 
    {
        path: '', component: AppComponent,
        children: [
            {
                path: 'home',
                loadChildren: './main/home/home.module#HomeModule',
                data: { breadcrumb: 'Home' },
                canActivate: [AuthGuard]
            },
            {
                path: 'tenants',
                loadChildren: './tenants/tenants.module#TenantsModule',
                data: { breadcrumb: 'Tenants' },
                canActivate: [AuthGuard]
            },
            {
                path: 'roles',
                loadChildren: './roles/roles.module#RolesModule',
                data: { breadcrumb: 'Roles' },
                canActivate: [AuthGuard]
            },    
            {
                path: 'not-found', component: NotFoundComponent
            }
        ]
    },
    // { path: '**',  redirectTo: '/not-found' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes, 
            { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
