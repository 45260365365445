import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { OAuthService, OAuthStorage, OAuthErrorEvent } from 'angular-oauth2-oidc';
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
import * as i2 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(oauthService, oauthStorage, router) {
        var _this = this;
        this.oauthService = oauthService;
        this.oauthStorage = oauthStorage;
        this.router = router;
        this.isAuthenticatedSubject$ = new BehaviorSubject(false);
        this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
        this.isDoneLoadingSubject$ = new ReplaySubject();
        this.isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
        /**
         * Publishes `true` if and only if (a) all the asynchronous initial
         * login calls have completed or errored, and (b) the user ended up
         * being authenticated.
         *
         * In essence, it combines:
         *
         * - the latest known state of whether the user is authorized
         * - whether the ajax calls for initial login have all been done
         * */
        this.canActivateProtectedRoutes$ = combineLatest(this.isAuthenticated$, this.isDoneLoading$).pipe(map(function (values) { return values.every(function (b) { return b; }); }));
        // useful for debugging
        this.oauthService.events.subscribe(function (event) {
            if (event instanceof OAuthErrorEvent) {
                console.error(event);
            }
        });
        // This is tricky, as it might cause race conditions
        // (where access_token is set in another)
        // tab before everything is said and done there.
        window.addEventListener('storage', function (event) {
            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key != null) {
                return;
            }
            console.warn('Noticed changes to access_tokent (most likely from another tab), updating isAuthenticated');
            _this.isAuthenticatedSubject$.next(_this.oauthService.hasValidAccessToken());
            if (!_this.oauthService.hasValidAccessToken()) {
                _this.navigateToLoginPage();
            }
        });
        // Subscribe to all events and peform check on token validity
        this.oauthService.events
            .subscribe(function (_) {
            _this.isAuthenticatedSubject$.next(_this.oauthService.hasValidAccessToken());
        });
        this.oauthService.events
            .pipe(filter(function (e) {
            return ['token_received'].includes(e.type);
        }))
            .subscribe(function (e) {
            return _this.oauthService.loadUserProfile();
        });
        this.oauthService.events
            .pipe(filter(function (e) { return ['session_terminated', 'session_error'].includes(e.type); }))
            .subscribe(function (e) {
            console.log('Your session has been terminated ! ');
            _this.navigateToLoginPage();
        });
        this.oauthService.setupAutomaticSilentRefresh();
    }
    AuthService.prototype.navigateToLoginPage = function () {
        // Remember current Url
        this.router.navigateByUrl('/login');
    };
    // End of Constructor ------------------------------------
    AuthService.prototype.runInitialLoginSequence = function () {
        var _this = this;
        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table...');
            console.table(location.hash.substr(1).split('&').map(function (kvp) { return kvp.split('='); }));
        }
        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        return this.oauthService.loadDiscoveryDocument()
            // For demo purposes, we pretend the previous call was
            // very slow 
            // .then(() => new Promise (resolve => setTimeout(() => resolve(), 1000)))
            // 1. HASH LOGIN
            // Try to log in via hash fragment after redirect back
            // from IdServer from initImplicitFlow:
            .then(function () { return _this.oauthService.tryLogin(); })
            .then(function () {
            if (_this.oauthService.hasValidAccessToken()) {
                return Promise.resolve(); // Done !
            }
            // 2. SILENT LOGIN
            // Try to log in via silent refresh because IdServer
            // might have a cookie to remember the user, so we can
            // prevent doing a redirect:
            return _this.oauthService.silentRefresh()
                .then(function () { return Promise.resolve(); })
                .catch(function (result) {
                // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                // Only the ones where it's reasonably sure that sending the
                // user to the IdServer will help.
                var errorResponsesRequiringUserInteraction = [
                    'interaction_required',
                    'login_required',
                    'account_selection_required',
                    'consent_required',
                ];
                if (result
                    && result.reason
                    && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
                    // 3. ASK FOR LOGIN: 
                    // At this point we know for sure that we have to ask
                    // the user to log in, so we redirect them to the IdServer
                    // to enter credentials
                    //
                    // Enable this to ALWAYS force a user to login
                    // this.oauthService.initImplicitFlow();
                    // 
                    // Instead, we'll now do this:
                    console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                    return Promise.resolve();
                }
                // We can't handle the truth, just pass on the problem to the
                // next handler
                return Promise.reject(result);
            });
        })
            .then(function () {
            _this.isDoneLoadingSubject$.next(true);
            // Check for the strings 'undefined' and 'null' just
            // to be sure. Our current login(...) should never have this,
            // but in case someone ever calls 
            // initImplicitFlow(undefined | null), this could happen.
            if (_this.oauthService.state
                && _this.oauthService.state !== 'undefined'
                && _this.oauthService.state !== 'null') {
                console.log('There was state, sowe are sending you to: ' + _this.oauthService.state);
                _this.router.navigateByUrl(_this.oauthService.state);
            }
        })
            .catch(function () { return _this.isDoneLoadingSubject$.next(true); });
    };
    // End of runInitialLoginSequence() ------------------------
    AuthService.prototype.login = function (targetUrl) {
        this.oauthService.initImplicitFlow(encodeURIComponent(targetUrl || this.router.url));
    };
    AuthService.prototype.logout = function (redirectToLogoutUrl) {
        if (redirectToLogoutUrl === void 0) { redirectToLogoutUrl = false; }
        this.oauthService.logOut(redirectToLogoutUrl);
    };
    AuthService.prototype.refresh = function () {
        this.oauthService.silentRefresh();
    };
    AuthService.prototype.hasValidToken = function () {
        return this.oauthService.hasValidAccessToken();
    };
    AuthService.prototype.refreshToken = function () {
        return this.oauthService.silentRefresh();
    };
    AuthService.prototype.getAccessTokenExpiration = function () {
        return this.oauthService.getAccessTokenExpiration();
    };
    AuthService.prototype.getIdTokenExpiration = function () {
        return this.oauthService.getIdTokenExpiration();
    };
    AuthService.prototype.getAccessToken = function () {
        return this.oauthService.getAccessToken();
    };
    /**
     * Checks if there is access_token and id_token in storage
     * @memberOf AuthService
     */
    AuthService.prototype.isStatelessSession = function () {
        if (this.oauthStorage.getItem('access_token') &&
            this.oauthStorage.getItem('id_token') &&
            this.oauthStorage.getItem('expires_at')) {
            return true;
        }
        return false;
    };
    Object.defineProperty(AuthService.prototype, "accessToken", {
        // These normally won't be exposed from a service like this, but
        // for debugging it makes sense.
        get: function () {
            return this.getAccessToken();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "identityClaims", {
        get: function () {
            return this.oauthService.getIdentityClaims();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "idToken", {
        get: function () {
            return this.oauthService.getIdToken();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "logoutUrl", {
        get: function () {
            return this.oauthService.logoutUrl;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.OAuthService), i0.inject(i1.OAuthStorage), i0.inject(i2.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
