import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, tap, catchError } from 'rxjs/operators';
import { empty, throwError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ITenantsApi, Tenant, User, IUsersResult } from 'app/tenants/tenant.model';


@Injectable()
export class TenantsService {

    private baseUrl: string;
    private tenantsBaseUrl: string;
    /**
    * Constructor
    * @param {HttpClient} _httpClient
    */
    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.backEnd;
        this.tenantsBaseUrl = this.baseUrl + '/api/tenantAdmin';
    }

    /**
     * 
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {string} order 
     * @param {string} sort 
     * @param {string} filter 
     * @returns {Observable<ITenantsApi>} 
     * 
     * @memberOf TenantsService
     */
    getTenants(page: number, limit: number, order: string, sort: string, filter: string):
        Observable<ITenantsApi> {

        const requestUrl =
            `${this.tenantsBaseUrl}?q=${filter}&sort=${sort}&order=${order}&page=${page + 1}&limit=${limit}`;

        const result = this.httpClient
            .get<ITenantsApi>(requestUrl, { observe: 'response' })
            // .subscribe(
            //     (x: ITenantsApi) => {
            //         console.log('Your data: x ')
            //     }
            // );
            // .get<any>(requestUrl)
            .pipe(
                // tap(x => console.log('Your data: x ' + x)),                
                map(response => {
                    return <ITenantsApi>{
                        // @ temporary for fake-json-server 
                        // @ using body bcs we are reading the full response using param: { observe: 'response' }
                        // @ returning full response in order to get X-Total from headers
                        items: response.body.items,
                        // total_count: +response.headers.get('X-Total-Count') || response.body.length
                        total: response.body.total
                    };
                }),
                catchError((err, caught) => {
                    console.log('Your err: ' + err);
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    return empty();
                    // return observableOf([]);
                })
            );

        return result;
    }

    createTenant(data): Observable<Tenant> {
        return this.httpClient
            .post<Tenant>(this.tenantsBaseUrl, data);
    }

    getTenantById(id): Observable<Tenant> {
        return this.httpClient
            .get<Tenant>(this.tenantsBaseUrl + '/' + id);
    }

    getUserById(userId: string, tenantId: string): Observable<User> {
        return this.httpClient
            .get<User>(`${this.tenantsBaseUrl}/${tenantId}/users/${userId}`);
    }
    updateTenant(id, data): Observable<void> {
        return this.httpClient
            .put<void>(this.tenantsBaseUrl + '/' + id, data);
    }

    getUsersByTenantId(id: string, text: string, page: number, limit: number): Observable<IUsersResult> {
        const requestUrl = `${this.tenantsBaseUrl + '/' + id + '/users'}?text=${text}&page=${page}&limit=${limit}`;
        const result = this.httpClient.get<IUsersResult>(requestUrl);
        return result;
    }

    createUser(user: User): Observable<User> {

        return this.httpClient
            .post<User>(this.tenantsBaseUrl + '/' + user.tenantId + '/users', user);
    }

    updateUser(tenantId, user: User): Observable<User> {

        return this.httpClient
            .put<User>(this.tenantsBaseUrl + '/' + tenantId + '/users/' + user.id, user);
    }

    removeUser(userId: string, tenantId: string): Observable<User> {
        return this.httpClient.delete<User>(
            this.tenantsBaseUrl + '/' + tenantId + '/users/' + userId
        );
    }

    resetUserPassword(userId: string, tenantId): Observable<any> {
        return this.httpClient.post<User>(
            this.tenantsBaseUrl +
            '/' +
            tenantId +
            '/users/' +
            userId +
            '/reset',
            null
        );
    }
    resendInvitation(userId: string, tenantId: string): Observable<any> {
        return this.httpClient.post<User>(
            this.tenantsBaseUrl +
            '/' +
            tenantId +
            '/users/' +
            userId +
            '/resendEmail',
            null
        );
    }


    handleError(error: HttpErrorResponse) {
        console.log('lalalalalalalala');
        return throwError(error);
    }
}
