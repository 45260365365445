import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { AuthService } from './auth-service';

@Injectable()
export class AuthPublicGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private _router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {

        return this.authService.canActivateProtectedRoutes$
            .pipe(
                // tap(x => console.log('You tried to go to public ' + state.url + ' and this guard said ' + x)),
                tap(canActivate => {
                    // @ Is authenticated ?
                    if (canActivate) {
                        const previousUrl = localStorage.getItem('previousUrl');
                        // @ Redirect to Url ?
                        if (previousUrl !== '') {
                            // @ Clear storage
                            localStorage.setItem('previousUrl', '');
                            this._router.navigateByUrl(previousUrl);
                            return of(false);
                        } else {
                            // @ Authenticated but there is not redirect url
                            this._router.navigateByUrl('/');
                        }
                    }
                })
            )
            // @ Not authenticaed, then no redirect the user.
            // then user can access this page
            .pipe(
                map(canActivate => !canActivate))
            ;
    }
}
