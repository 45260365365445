import { Injectable } from '@angular/core';
import { 
    CanActivate, 
    ActivatedRouteSnapshot, 
    RouterStateSnapshot, 
    Router 
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth-service';

@Injectable()
export class AuthGuard implements CanActivate {

    currentUrl: any;
    previousUrl: any;

    constructor(
        private authService: AuthService,
        private _router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {

        return this.authService.canActivateProtectedRoutes$
            .pipe(
                // tap(x => console.log('You tried to go to ' + state.url + ' and this guard said ' + x)),
                tap(canActivate => {
                    // @ Not Authenticated, then redirect to login and save state is storage
                    if (!canActivate) {
                        localStorage.setItem('previousUrl', state.url);
                        this._router.navigate(['/login']).then(result => {
                           console.log(`navigated to /login result: ${result}`);
                        });
                       // this._router.navigateByUrl('/login');
                    }
                })
            );
    }
}
