import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {
    AuthConfig,
    JwksValidationHandler,
    OAuthModule,
    OAuthModuleConfig,
    ValidationHandler,
    OAuthResourceServerErrorHandler,
    OAuthStorage
} from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { OAuthCustomResourceServerErrorHandler } from './auth-error';
import { authModuleConfig } from './auth-module-config';
import { authConfig } from './auth-config';
import { AuthService } from './auth-service';
import { AuthGuard } from './auth-guard.service';
import { AuthGuardWithForcedLogin } from './auth-guard-with-forced-login.service';
import { AuthPublicGuard } from './auth-public-guard.service';
import { AuthInterceptor } from './auth-interceptor';


@NgModule({
    imports: [
        HttpClientModule,
        OAuthModule.forRoot()
    ],
    providers: [
        AuthService,
        AuthGuard,
        AuthPublicGuard,
        AuthGuardWithForcedLogin
    ]
})
export class SecurityModule {

    static forRoot(): ModuleWithProviders {
        return {
          ngModule: SecurityModule,
          providers: [
              { provide: AuthConfig, useValue: authConfig },
              { provide: OAuthModuleConfig, useValue: authModuleConfig },
              { provide: ValidationHandler, useClass: JwksValidationHandler},
            //   { provide: OAuthStorage, useValue: localStorage},
              {
                  provide: OAuthResourceServerErrorHandler,
                  useClass: OAuthCustomResourceServerErrorHandler
              },
              {
                  provide: HTTP_INTERCEPTORS,
                  useClass: AuthInterceptor,
                  multi: true
              }
          ]  
        };
    }

    constructor (
        @Optional() @SkipSelf() parentModule: SecurityModule
    ) {

        if (parentModule) {
            throw new Error(
                'SecurityModule is already loaded. Import it in the AppModule only'
            );
        }        
    }
}
