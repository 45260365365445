import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';

import { AuthService } from 'app/blocks/security/auth-service';
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, AfterViewInit {
    loginForm: FormGroup;
    previousUrl: any;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private _router: Router,
    ) {
        console.log('Inside the Login Component');
    }  

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            // email: ['', [Validators.required, Validators.email]],
            // password: ['', Validators.required]
        });


    }
    
    ngAfterViewInit(): void {
        const previousUrl = localStorage.getItem('previousUrl');
        const loggedIn = this.authService.hasValidToken();

        if (loggedIn && previousUrl !== '') {
            setTimeout(() => {
                this._router.navigateByUrl(previousUrl);
                localStorage.setItem('previousUrl', '');
            }, 100);
        }
    }

    login($event): void {
        // $event.preventDefault();
        this.authService.login('/home');
    }
}
