import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    BehaviorSubject,
    ReplaySubject,
    Observable,
    combineLatest
} from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {
    OAuthService,
    OAuthStorage,
    OAuthErrorEvent,
    OAuthEvent
} from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errored, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial login have all been done
     * */

    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest(
        this.isAuthenticated$,
        this.isDoneLoading$
    ).pipe(map(values => values.every(b => b)));

    private navigateToLoginPage(): void {
        // Remember current Url
        this.router.navigateByUrl('/login');
    }

    constructor(
        private oauthService: OAuthService,
        private oauthStorage: OAuthStorage,
        private router: Router
    ) {
        // useful for debugging
        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                console.error(event);
            }
        });

        // This is tricky, as it might cause race conditions
        // (where access_token is set in another)
        // tab before everything is said and done there.
        window.addEventListener('storage', event => {
            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key != null) {
                return;
            }

            console.warn(
                'Noticed changes to access_tokent (most likely from another tab), updating isAuthenticated'
            );
            this.isAuthenticatedSubject$.next(
                this.oauthService.hasValidAccessToken()
            );
            if (!this.oauthService.hasValidAccessToken()) {
                this.navigateToLoginPage();
            }
        });

        // Subscribe to all events and peform check on token validity
        this.oauthService.events
        .subscribe(_ => {
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        });

        this.oauthService.events
            .pipe(
                filter(e => 
                    ['token_received'].includes(e.type)))
            .subscribe(e => 
                this.oauthService.loadUserProfile());
  
        this.oauthService.events
            .pipe(
                filter(
                    e => ['session_terminated', 'session_error'].includes(e.type)
                ))
                .subscribe(
                    e => {
                        console.log('Your session has been terminated ! ');
                        this.navigateToLoginPage();
                    }
                );

        this.oauthService.setupAutomaticSilentRefresh();
    }
    // End of Constructor ------------------------------------
    
    public runInitialLoginSequence(): Promise<void> {

        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table...');
            console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
        }

        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        return this.oauthService.loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was
        // very slow 
        // .then(() => new Promise (resolve => setTimeout(() => resolve(), 1000)))

        // 1. HASH LOGIN
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())

        .then(() => {
            if (this.oauthService.hasValidAccessToken()) {
                return Promise.resolve(); // Done !
            }

            // 2. SILENT LOGIN
            // Try to log in via silent refresh because IdServer
            // might have a cookie to remember the user, so we can
            // prevent doing a redirect:
            return this.oauthService.silentRefresh()
                .then(() => Promise.resolve())
                .catch(result => {

                    // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                    // Only the ones where it's reasonably sure that sending the
                    // user to the IdServer will help.
                    const errorResponsesRequiringUserInteraction = [
                        'interaction_required',
                        'login_required',
                        'account_selection_required',
                        'consent_required',
                    ];
        
                    if (result
                        && result.reason
                        && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
                            
                            // 3. ASK FOR LOGIN: 
                            // At this point we know for sure that we have to ask
                            // the user to log in, so we redirect them to the IdServer
                            // to enter credentials
                            //
                            // Enable this to ALWAYS force a user to login
                            // this.oauthService.initImplicitFlow();
                            // 
                            // Instead, we'll now do this:
                            console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                            return Promise.resolve();
                        }
  
                        // We can't handle the truth, just pass on the problem to the
                        // next handler
                        return Promise.reject(result);
                });
        })

        .then(() => {
            this.isDoneLoadingSubject$.next(true);

            // Check for the strings 'undefined' and 'null' just
            // to be sure. Our current login(...) should never have this,
            // but in case someone ever calls 
            // initImplicitFlow(undefined | null), this could happen.
            if (this.oauthService.state 
                && this.oauthService.state !== 'undefined' 
                && this.oauthService.state !== 'null') {

                    console.log('There was state, sowe are sending you to: ' + this.oauthService.state);
                    this.router.navigateByUrl(this.oauthService.state);
                }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true));
    }
    // End of runInitialLoginSequence() ------------------------

    public login(targetUrl?: string): void {
        this.oauthService.initImplicitFlow(
            encodeURIComponent(targetUrl || this.router.url)
        );
    }
    
    public logout(redirectToLogoutUrl: boolean = false): void {
        this.oauthService.logOut(redirectToLogoutUrl);
    }

    public refresh(): void { 
        this.oauthService.silentRefresh();
    }

    public hasValidToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    public refreshToken(): Promise<OAuthEvent> {
        return this.oauthService.silentRefresh();
    }

    public getAccessTokenExpiration(): number {
        return this.oauthService.getAccessTokenExpiration();
    }

    public getIdTokenExpiration(): number {
        return this.oauthService.getIdTokenExpiration();
    }

    public getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    /**
     * Checks if there is access_token and id_token in storage
     * @memberOf AuthService
     */
    public isStatelessSession(): boolean {

        if (this.oauthStorage.getItem('access_token') &&
        this.oauthStorage.getItem('id_token') && 
        this.oauthStorage.getItem('expires_at')) {
            return true;
        }
        return false;
    }

    // These normally won't be exposed from a service like this, but
    // for debugging it makes sense.
    public get accessToken(): string { 
        return this.getAccessToken(); 
    }

    public get identityClaims(): object {
        return this.oauthService.getIdentityClaims();
    }

    public get idToken(): string {
        return this.oauthService.getIdToken();
    }

    public get logoutUrl(): string {
        return this.oauthService.logoutUrl;
    }
}
