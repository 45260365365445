/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./shell.component";
import * as i5 from "../../../@fuse/services/splash-screen.service";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "../../blocks/security/auth-service";
import * as i8 from "@angular/service-worker";
var styles_ShellComponent = [i0.styles];
var RenderType_ShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShellComponent, data: {} });
export { RenderType_ShellComponent as RenderType_ShellComponent };
function View_ShellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShellComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shell", [], null, null, null, View_ShellComponent_0, RenderType_ShellComponent)), i1.ɵdid(1, 245760, null, 0, i4.ShellComponent, [i5.FuseSplashScreenService, i6.Platform, i7.AuthService, i8.SwUpdate], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShellComponentNgFactory = i1.ɵccf("shell", i4.ShellComponent, View_ShellComponent_Host_0, {}, {}, []);
export { ShellComponentNgFactory as ShellComponentNgFactory };
