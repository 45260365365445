import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import 'hammerjs';
var ɵ0 = MAT_MOMENT_DATE_FORMATS;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
