import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, tap, catchError } from 'rxjs/operators';
import { empty, throwError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ITenantsApi, Tenant, User, IUsersResult, Subscription, Plan } from 'app/tenants/tenant.model';


@Injectable()
export class PlansService {

    private baseUrl: string;
    private apiBaseUrl: string;
    /**
    * Constructor
    * @param {HttpClient} _httpClient
    */
    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.backEnd;
        this.apiBaseUrl = this.baseUrl + '/api/plans';
    }

    getPlans(): Observable<Plan[]> {
        return this.httpClient
            .get<Plan[]>(`${this.apiBaseUrl}`);
    }
    getPlanByName(name: string): Observable<Plan> {
        return this.httpClient
            .get<Plan>(`${this.apiBaseUrl}/name/${name}`);
    }

    getPlanById(id: string): Observable<Plan> {
        return this.httpClient
              .get<Plan>(`${this.apiBaseUrl}/${id}`);
      }

}
