import { InternetConnectionService } from './internet-connection.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { TenantsService } from './tenants.service';
import { RolesService } from './roles.service';
import { SubscriptionsService } from './subscriptions.service';
import { PlansService } from './plans.service';

@NgModule({
    declarations: [
    ],
    imports: [
    ],
    providers: [
        // TenantsService,
        // InternetConnectionService
    ]
})
export class ServicesModule {

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ServicesModule,
            providers: [
                TenantsService,
                PlansService,
                SubscriptionsService,
                RolesService,
                InternetConnectionService,
            ]
        };
    }
}
