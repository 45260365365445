import { AjaxResponse } from 'rxjs/ajax';
import { throwError } from 'rxjs/internal/observable/throwError';
declare var Swal: any;

export function handleError(error: AjaxResponse) {
    let errorMessage = "Oops! Something went wrong. Please try again later."
    if (error.status === 400 && error.response) {

        if (error.response.message && typeof error.response.message == 'string') {
            errorMessage = error.response.message
        } else {
            errorMessage = Object.keys(error.response)
                .map(key => `<div>${error.response[key].join('\n')}</div>`)
                .join('\n');
        }

    } else {
        console.error('Error:', error);
    }

    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: errorMessage,
    });

    return throwError(error);
}

export function parseBoolean(value){
    if (typeof value === 'boolean') {
        return value;
      } else if (typeof value === 'string') {
        const lowerValue = value.toLowerCase();
        if (lowerValue === 'true') {
          return true;
        } else if (lowerValue === 'false') {
          return false;
        }
      }
}