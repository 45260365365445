import { HttpResponse } from '@angular/common/http';
import { OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { throwError, Observable } from 'rxjs';

export class OAuthCustomResourceServerErrorHandler
    implements OAuthResourceServerErrorHandler {
    
    handleError(err: HttpResponse<any>): Observable<any> {
        // console.error(err);
        return throwError(err);
    }
}
