<div class="page-layout blank" fusePerfectScrollbar >
    <div id="error-404" fxLayout="column" fxLayoutAlign="center center">

        <div class="content" fxLayout="column" fxLayoutAlign="center center">
      
            <h1>
                Not found
              </h1>
            <!-- <div class="error-code">404</div> -->
      
            <div class="message">Sorry but we could not find the page you are looking for</div>
            
            <a class="back-link" [routerLink]="'/home'">Go back to Home</a>      
        </div>      
      </div>
</div>

